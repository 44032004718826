<template>
    <div class="space-y-4 py-4 px-2 max-md:px-1" v-if="similarArticles.all.length">
        <h2 class="font-medium text-lg text-gray-700">Articole similare</h2>
        <!-- Tab Buttons -->
        <div class="flex justify-start space-x-2 mb-4">
            <template v-for="tab in tabs" :key="tab.key">
                <button
                    v-if="similarArticles[tab.key].length"
                    @click="selectedTab = tab.key"
                    :class="[
            'px-2 rounded-lg border h-[35px] text-xs font-medium shadow-sm transition-all duration-200',
            selectedTab === tab.key
                ?  'text-main border-main scale-105'
                : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-200 hover:border-gray-400',
                similarArticles[tab.key].length ? '' : 'disabled'
        ]"
                    :aria-pressed="selectedTab === tab.key"
                >
                    {{ tab.label }} ({{ similarArticles[tab.key].length }})
                </button>
            </template>
        </div>

        <!-- Tab Content -->
        <div>
            <div v-if="selectedTab === 'all'">
                <ArticleList  :visible-articles="similarArticles.all" />
            </div>
            <div v-else-if="selectedTab === 'left'">
                <ArticleList :visible-articles="similarArticles.left" />
            </div>
            <div v-else-if="selectedTab === 'center'">
                <ArticleList :visible-articles="similarArticles.center" />
            </div>
            <div v-else-if="selectedTab === 'right'">
                <ArticleList :visible-articles="similarArticles.right" />
            </div>
        </div>
    </div>
</template>

<script>

import ArticleList from "../Components/ArticleList.vue";

export default {
    components: {
        ArticleList,
    },
    props: {
        similarArticles: {
            type: Object,
            required: true, // Ensure the similar articles data is passed
            default: () => ({
                all: [],
                left: [],
                center: [],
                right: [],
            }),
        },
    },
    data() {
        return {
            selectedTab: "all", // Default tab is "All"
            tabs: [
                { key: "all", label: "Toate" },
                { key: "left", label: "Stanga" },
                { key: "center", label: "Centru" },
                { key: "right", label: "Dreapta" },
            ],
        };
    },
};
</script>

<style scoped>
/* Add styles for a polished look */
button {
    transition: background-color 0.2s, color 0.2s;
}
</style>
